<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <!-- <v-card elevation-1>
      <v-layout wrap justify-start>
        <v-flex text-center xs12 lg7 px-12>
          <v-layout wrap justify-space-between py-2>
            <v-flex v-for="(item, i) in category" :key="i" px-2>
              <router-link
              :to="'/Shopping?category=' + item._id + '&name=' + item.name"
            >
              <v-btn block text :ripple="false">
                <span
                  style="
                    font-family: sofiaProSemiBold;
                    font-size: 15px;
                    color: #343434;
                    text-transform: none;
                  "
                >
                  {{ item.name }}
                </span>
              </v-btn>
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
     -->

    <v-layout wrap justify-center>
      <!-- <v-flex xs12>
        <FilterHeader />
      </v-flex> -->
      <!-- <v-flex xs12 md2 lg2>
        <ShoppingMenu /> -->
      <!-- <ShoppingSide/> -->
      <!-- </v-flex> -->
      <v-flex
        xs12
        sm12
        md12
        lg12
        :style="
          $vuetify.breakpoint.name == 'lg'
            ? 'padding-top:170px !important'
            : $vuetify.breakpoint.name == 'md'
            ? 'padding-top:30px !important'
            : $vuetify.breakpoint.name == 'sm'
            ? 'padding-top:10px !important'
            : $vuetify.breakpoint.name == 'xs'
            ? 'padding-top:10px !important'
            : 'padding-top:170px !important'
        "
      >
        <router-view></router-view>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ShoppingMenu from "./shoppingMenu";
// import FilterHeader from "./filterHeader";
// import ShoppingSide from "./shoppingSide";
export default {
  components: {
    // ShoppingMenu,
    // FilterHeader,
    // ShoppingSide
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      category: [
        { name: "Hats" },
        { name: "Photo Frames" },
        { name: "Shirts" },
        { name: "Trekking Shorts" },
      ],
    };
  },
};
</script>
